import React from "react";
import AvatarGroup from "@atlaskit/avatar-group";
import { CustomLink } from "../link/link.comp";
import { StyledHero } from "./hero.styles";
import dynamic from "next/dynamic";
import { SocialProof } from "../socialProof/socialProof.comp";

const BracketsHeroImage = dynamic(() =>
  import("../bracketsHeroImage/bracketsHeroImage.comp"),
  {
    ssr: false
  }
);

export const Hero = () => {
  return (
    <StyledHero>
      <div className="hero-content-container">
        <div className="details">
          <h1>
            Ninja Bracket Maker
          </h1>
          <h2>
            Create advanced online brackets & tournaments with images, voting, predictions, and real-time updates.
          </h2>
          <CustomLink href="https://www.commoninja.com/brackets/editor?via=brackets" title="Create an Online Bracket">
            Create an Online Bracket
          </CustomLink>
          <SocialProof />
        </div>
      </div>
      <BracketsHeroImage />
    </StyledHero>
  );
};

const users = [
  {
    name: "Matthew Brown",
    src: "https://website-assets.commoninja.com/distribution/1672237892897_header-person-1.png",
  },
  {
    name: "Sam Taylor",
    src: "https://website-assets.commoninja.com/distribution/1672237905508_header-person-2.png",
  },
  {
    name: "Madison Reed",
    src: "https://website-assets.commoninja.com/distribution/1672237913749_header-person-3.png",
  },
  {
    name: "Daniel Levy",
    src: "https://website-assets.commoninja.com/distribution/1672237920681_header-person-4.png",
  },
  {
    name: "Emma Jones",
    src: "https://website-assets.commoninja.com/distribution/1672237927653_header-person-5.png",
  },
];
