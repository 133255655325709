import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.a`
	color: inherit;
	background: none !important;
	width: fit-content;
	padding: 0 !important;

	display: flex;
	align-items: center;
	gap: 15px;
	flex-wrap: wrap;
	justify-content: flex-start;

	.rating {
		display: flex;
		flex-direction: column;
		gap: 5px;
		/* max-width: 170px; */

		p {
			font-size: ${CSSVars.fontSizeXS};
			font-weight: 300;
			text-align: left;
		}
	}

	@media screen and (min-width: ${CSSVars.breakpointSM}) {
		justify-content: center;
	}
`;
