import React from 'react';

export const BracketIcon = ({ type }: { type: string; }) => {
    switch (type) {
        case '1':

            return (
                <svg width="272" height="162" viewBox="0 0 272 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_269_7227)">
                        <path d="M11 16C11 13.2386 13.2386 11 16 11H256C258.761 11 261 13.2386 261 16V151H11V16Z" fill="url(#paint0_linear_269_7227)" shapeRendering="crispEdges" />
                        <path d="M261 151.5H261.5V151V16C261.5 12.9624 259.038 10.5 256 10.5H16C12.9624 10.5 10.5 12.9624 10.5 16V151V151.5H11H261Z" stroke="url(#paint1_linear_269_7227)" shapeRendering="crispEdges" />
                    </g>
                    <path d="M11 16C11 13.2386 13.2386 11 16 11H256C258.761 11 261 13.2386 261 16V26H11V16Z" fill="#F9FAFB" />
                    <circle cx="21.5" cy="18.5" r="2.5" fill="#F46059" />
                    <circle cx="29.5" cy="18.5" r="2.5" fill="#FBB751" />
                    <circle cx="37.5" cy="18.5" r="2.5" fill="#4ABE52" />
                    <rect x="91" y="43" width="65" height="30" rx="5" fill="#D7DBE2" />
                    <rect x="186" y="61" width="85" height="40" rx="5" fill="#6881E9" />
                    <rect x="91" y="88" width="65" height="30" rx="5" fill="#D7DBE2" />
                    <path d="M156 58H169C170.105 58 171 58.8954 171 60V101C171 102.105 170.105 103 169 103H156" stroke="#DFE2E4" strokeWidth="2" />
                    <path d="M39 41H74C75.1046 41 76 41.8954 76 43V73C76 74.1046 75.1046 75 74 75H28" stroke="url(#paint2_linear_269_7227)" strokeWidth="2" />
                    <path d="M51 86H74C75.1046 86 76 86.8954 76 88V118C76 119.105 75.1046 120 74 120H41" stroke="url(#paint3_linear_269_7227)" strokeWidth="2" />
                    <path d="M186 81L171 81" stroke="#DFE2E4" strokeWidth="2" />
                    <path d="M91 58L76 58" stroke="#DFE2E4" strokeWidth="2" />
                    <path d="M91 103L76 103" stroke="#DFE2E4" strokeWidth="2" />
                    <defs>
                        <filter id="filter0_d_269_7227" x="0" y="0" width="272" height="162" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset />
                            <feGaussianBlur stdDeviation="5" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_269_7227" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_269_7227" result="shape" />
                        </filter>
                        <linearGradient id="paint0_linear_269_7227" x1="136" y1="11" x2="136" y2="151" gradientUnits="userSpaceOnUse">
                            <stop offset="0.7744" stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_269_7227" x1="136" y1="11" x2="136" y2="151" gradientUnits="userSpaceOnUse">
                            <stop offset="0.7744" stopColor="#E4E7EB" />
                            <stop offset="1" stopColor="#E4E7EB" stopOpacity="0" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_269_7227" x1="76" y1="58" x2="33" y2="46.5" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#ECEFF2" />
                            <stop offset="1" stopColor="#ECEFF2" stopOpacity="0" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_269_7227" x1="77.5" y1="103" x2="39.5" y2="94" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#ECEFF2" />
                            <stop offset="1" stopColor="#ECEFF2" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
            );

        case '2':
            return (
                <svg width="272" height="162" viewBox="0 0 272 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_280_453)">
                        <path d="M11 16C11 13.2386 13.2386 11 16 11H256C258.761 11 261 13.2386 261 16V151H11V16Z" fill="url(#paint0_linear_280_453)" shapeRendering="crispEdges" />
                        <path d="M261 151.5H261.5V151V16C261.5 12.9624 259.038 10.5 256 10.5H16C12.9624 10.5 10.5 12.9624 10.5 16V151V151.5H11H261Z" stroke="url(#paint1_linear_280_453)" shapeRendering="crispEdges" />
                    </g>
                    <rect x="70" y="36" width="52" height="25" rx="5" fill="#D7DBE2" />
                    <rect x="70" y="76" width="52" height="25" rx="5" fill="#D7DBE2" />
                    <rect x="138" y="56" width="52" height="25" rx="5" fill="#D7DBE2" />
                    <rect x="98" y="116" width="52" height="25" rx="5" fill="#D7DBE2" />
                    <rect width="65" height="30" rx="5" transform="matrix(-1 0 0 1 271 84)" fill="#6881E9" />
                    <path d="M122 49H128C129.105 49 130 49.8954 130 51V87C130 88.1046 129.105 89 128 89H122" stroke="#DFE2E4" strokeWidth="2" />
                    <path d="M190 69H196C197.105 69 198 69.8954 198 71V127C198 128.105 197.105 129 196 129H150" stroke="#DFE2E4" strokeWidth="2" />
                    <path d="M46 36H60.5C61.6046 36 62.5 36.8954 62.5 38V59C62.5 60.1046 61.6046 61 60.5 61H35" stroke="url(#paint2_linear_280_453)" strokeWidth="2" />
                    <path d="M23.5 76H60.5C61.6046 76 62.5 76.8954 62.5 78V99C62.5 100.105 61.6046 101 60.5 101H43.5" stroke="url(#paint3_linear_280_453)" strokeWidth="2" />
                    <path d="M138 69H130" stroke="#DFE2E4" strokeWidth="2" />
                    <path d="M206 99H198" stroke="#DFE2E4" strokeWidth="2" />
                    <path d="M70.5 49H62.5" stroke="#DFE2E4" strokeWidth="2" />
                    <path d="M70.5 89H62.5" stroke="#DFE2E4" strokeWidth="2" />
                    <path d="M98 129L68 129" stroke="url(#paint4_linear_280_453)" strokeWidth="2" />
                    <path d="M11 16C11 13.2386 13.2386 11 16 11H256C258.761 11 261 13.2386 261 16V26H11V16Z" fill="#F9FAFB" />
                    <circle cx="21.5" cy="18.5" r="2.5" fill="#F46059" />
                    <circle cx="29.5" cy="18.5" r="2.5" fill="#FBB751" />
                    <circle cx="37.5" cy="18.5" r="2.5" fill="#4ABE52" />
                    <defs>
                        <filter id="filter0_d_280_453" x="0" y="0" width="272" height="162" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset />
                            <feGaussianBlur stdDeviation="5" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_280_453" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_280_453" result="shape" />
                        </filter>
                        <linearGradient id="paint0_linear_280_453" x1="136" y1="11" x2="136" y2="151" gradientUnits="userSpaceOnUse">
                            <stop offset="0.7744" stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_280_453" x1="136" y1="11" x2="136" y2="151" gradientUnits="userSpaceOnUse">
                            <stop offset="0.7744" stopColor="#E4E7EB" />
                            <stop offset="1" stopColor="#E4E7EB" stopOpacity="0" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_280_453" x1="64.5" y1="49" x2="45" y2="41.5" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#ECEFF2" />
                            <stop offset="1" stopColor="#ECEFF2" stopOpacity="0" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_280_453" x1="64" y1="89" x2="39" y2="101" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#ECEFF2" />
                            <stop offset="1" stopColor="#ECEFF2" stopOpacity="0" />
                        </linearGradient>
                        <linearGradient id="paint4_linear_280_453" x1="98" y1="129" x2="69.4189" y2="129" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#ECEFF2" />
                            <stop offset="1" stopColor="#ECEFF2" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
            );

        case '3':
            return (<svg width="275" height="162" viewBox="0 0 275 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_269_7242)">
                    <path d="M12 16C12 13.2386 14.2386 11 17 11H257C259.761 11 262 13.2386 262 16V151H12V16Z" fill="url(#paint0_linear_269_7242)" shapeRendering="crispEdges" />
                    <path d="M262 151.5H262.5V151V16C262.5 12.9624 260.038 10.5 257 10.5H17C13.9624 10.5 11.5 12.9624 11.5 16V151V151.5H12H262Z" stroke="url(#paint1_linear_269_7242)" shapeRendering="crispEdges" />
                </g>
                <rect width="52" height="25" rx="5" transform="matrix(-1 0 0 1 237 45)" fill="#D7DBE2" />
                <rect x="36" y="45" width="52" height="25" rx="5" fill="#D7DBE2" />
                <rect width="52" height="25" rx="5" transform="matrix(-1 0 0 1 237 90)" fill="#D7DBE2" />
                <rect x="36" y="90" width="52" height="25" rx="5" fill="#D7DBE2" />
                <rect width="65" height="30" rx="5" transform="matrix(-1 0 0 1 169 66)" fill="#6881E9" />
                <path d="M185 58H179C177.895 58 177 58.8954 177 60V101C177 102.105 177.895 103 179 103H185" stroke="#DFE2E4" strokeWidth="2" />
                <path d="M88 58H94C95.1046 58 96 58.8954 96 60V101C96 102.105 95.1046 103 94 103H88" stroke="#DFE2E4" strokeWidth="2" />
                <path d="M0 45H26.5C27.6046 45 28.5 45.8954 28.5 47V68C28.5 69.1046 27.6046 70 26.5 70H9" stroke="url(#paint2_linear_269_7242)" strokeWidth="2" />
                <path d="M258 45H247C245.895 45 245 45.8954 245 47V68C245 69.1046 245.895 70 247 70H268.5" stroke="url(#paint3_linear_269_7242)" strokeWidth="2" />
                <path d="M16.5 90H26.5C27.6046 90 28.5 90.8954 28.5 92V113C28.5 114.105 27.6046 115 26.5 115H2" stroke="url(#paint4_linear_269_7242)" strokeWidth="2" />
                <path d="M275 90H247C245.895 90 245 90.8954 245 92V113C245 114.105 245.895 115 247 115H266.5" stroke="url(#paint5_linear_269_7242)" strokeWidth="2" />
                <path d="M169 81H177" stroke="#DFE2E4" strokeWidth="2" />
                <path d="M104 81H96" stroke="#DFE2E4" strokeWidth="2" />
                <path d="M36.5 58H28.5" stroke="#DFE2E4" strokeWidth="2" />
                <path d="M237 58H245" stroke="#DFE2E4" strokeWidth="2" />
                <path d="M36.5 103H28.5" stroke="#DFE2E4" strokeWidth="2" />
                <path d="M237 103H245" stroke="#DFE2E4" strokeWidth="2" />
                <path d="M12 16C12 13.2386 14.2386 11 17 11H257C259.761 11 262 13.2386 262 16V26H12V16Z" fill="#F9FAFB" />
                <circle cx="22.5" cy="18.5" r="2.5" fill="#F46059" />
                <circle cx="30.5" cy="18.5" r="2.5" fill="#FBB751" />
                <circle cx="38.5" cy="18.5" r="2.5" fill="#4ABE52" />
                <defs>
                    <filter id="filter0_d_269_7242" x="1" y="0" width="272" height="162" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset />
                        <feGaussianBlur stdDeviation="5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_269_7242" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_269_7242" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_269_7242" x1="137" y1="11" x2="137" y2="151" gradientUnits="userSpaceOnUse">
                        <stop offset="0.7744" stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_269_7242" x1="137" y1="11" x2="137" y2="151" gradientUnits="userSpaceOnUse">
                        <stop offset="0.7744" stopColor="#E4E7EB" />
                        <stop offset="1" stopColor="#E4E7EB" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_269_7242" x1="30.5" y1="58" x2="9.5" y2="64" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#ECEFF2" />
                        <stop offset="1" stopColor="#ECEFF2" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_269_7242" x1="242.5" y1="58" x2="259.5" y2="51" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#ECEFF2" />
                        <stop offset="1" stopColor="#ECEFF2" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_269_7242" x1="30" y1="103" x2="12.5" y2="95.5" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#ECEFF2" />
                        <stop offset="1" stopColor="#ECEFF2" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_269_7242" x1="243" y1="103" x2="258" y2="108" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#ECEFF2" />
                        <stop offset="1" stopColor="#ECEFF2" stopOpacity="0" />
                    </linearGradient>
                </defs>
            </svg>
            );
        case '4':
            return (<svg width="275" height="162" viewBox="0 0 275 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_269_7265)">
                    <path d="M13 16C13 13.2386 15.2386 11 18 11H258C260.761 11 263 13.2386 263 16V151H13V16Z" fill="url(#paint0_linear_269_7265)" shapeRendering="crispEdges" />
                    <path d="M263 151.5H263.5V151V16C263.5 12.9624 261.038 10.5 258 10.5H18C14.9624 10.5 12.5 12.9624 12.5 16V151V151.5H13H263Z" stroke="url(#paint1_linear_269_7265)" shapeRendering="crispEdges" />
                </g>
                <path d="M13 16C13 13.2386 15.2386 11 18 11H258C260.761 11 263 13.2386 263 16V26H13V16Z" fill="#F9FAFB" />
                <circle cx="23.5" cy="18.5" r="2.5" fill="#F46059" />
                <circle cx="31.5" cy="18.5" r="2.5" fill="#FBB751" />
                <circle cx="39.5" cy="18.5" r="2.5" fill="#4ABE52" />
                <path d="M170 81H185" stroke="#DFE2E4" strokeWidth="2" />
                <path d="M90 81H105" stroke="#DFE2E4" strokeWidth="2" />
                <rect x="185" y="49" width="90" height="63" rx="2" fill="#EEF1F4" />
                <rect y="49" width="90" height="63" rx="2" fill="#EEF1F4" />
                <rect width="65" height="30" rx="5" transform="matrix(-1 0 0 1 170 66)" fill="#6881E9" />
                <rect x="205" y="57" width="58" height="8" rx="4" fill="#D7DBE2" />
                <rect x="20" y="57" width="32" height="8" rx="4" fill="#D7DBE2" />
                <rect x="205" y="70" width="45" height="8" rx="4" fill="#D7DBE2" />
                <rect x="20" y="70" width="54" height="8" rx="4" fill="#D7DBE2" />
                <rect x="205" y="83" width="62" height="8" rx="4" fill="#D7DBE2" />
                <rect x="20" y="83" width="45" height="8" rx="4" fill="#D7DBE2" />
                <rect x="205" y="96" width="35" height="8" rx="4" fill="#D7DBE2" />
                <rect x="20" y="96" width="58" height="8" rx="4" fill="#D7DBE2" />
                <rect x="193" y="57" width="8" height="8" rx="4" fill="#D7DBE2" />
                <rect x="8" y="57" width="8" height="8" rx="4" fill="#D7DBE2" />
                <rect x="193" y="70" width="8" height="8" rx="4" fill="#D7DBE2" />
                <rect x="8" y="70" width="8" height="8" rx="4" fill="#D7DBE2" />
                <rect x="193" y="83" width="8" height="8" rx="4" fill="#D7DBE2" />
                <rect x="8" y="83" width="8" height="8" rx="4" fill="#D7DBE2" />
                <rect x="193" y="96" width="8" height="8" rx="4" fill="#D7DBE2" />
                <rect x="8" y="96" width="8" height="8" rx="4" fill="#D7DBE2" />
                <defs>
                    <filter id="filter0_d_269_7265" x="2" y="0" width="272" height="162" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset />
                        <feGaussianBlur stdDeviation="5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_269_7265" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_269_7265" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_269_7265" x1="138" y1="11" x2="138" y2="151" gradientUnits="userSpaceOnUse">
                        <stop offset="0.7744" stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_269_7265" x1="138" y1="11" x2="138" y2="151" gradientUnits="userSpaceOnUse">
                        <stop offset="0.7744" stopColor="#E4E7EB" />
                        <stop offset="1" stopColor="#E4E7EB" stopOpacity="0" />
                    </linearGradient>
                </defs>
            </svg>
            );
        case '5':
            return (
                <svg width="272" height="162" viewBox="0 0 272 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_269_7292)">
                        <path d="M11 16C11 13.2386 13.2386 11 16 11H256C258.761 11 261 13.2386 261 16V151H11V16Z" fill="url(#paint0_linear_269_7292)" shapeRendering="crispEdges" />
                        <path d="M261 151.5H261.5V151V16C261.5 12.9624 259.038 10.5 256 10.5H16C12.9624 10.5 10.5 12.9624 10.5 16V151V151.5H11H261Z" stroke="url(#paint1_linear_269_7292)" shapeRendering="crispEdges" />
                    </g>
                    <path d="M11 16C11 13.2386 13.2386 11 16 11H256C258.761 11 261 13.2386 261 16V26H11V16Z" fill="#F9FAFB" />
                    <circle cx="21.5" cy="18.5" r="2.5" fill="#F46059" />
                    <circle cx="29.5" cy="18.5" r="2.5" fill="#FBB751" />
                    <circle cx="37.5" cy="18.5" r="2.5" fill="#4ABE52" />
                    <rect x="140" y="34" width="90" height="50" rx="2" fill="#EEF1F4" />
                    <rect x="160" y="42" width="58" height="8" rx="4" fill="#D7DBE2" />
                    <rect x="160" y="55" width="45" height="8" rx="4" fill="#6881E9" />
                    <rect x="160" y="68" width="62" height="8" rx="4" fill="#D7DBE2" />
                    <rect x="148" y="42" width="8" height="8" rx="4" fill="#D7DBE2" />
                    <rect x="148" y="55" width="8" height="8" rx="4" fill="#6881E9" />
                    <rect x="148" y="68" width="8" height="8" rx="4" fill="#D7DBE2" />
                    <rect x="140" y="94" width="90" height="50" rx="2" fill="#EEF1F4" />
                    <rect x="160" y="102" width="32" height="8" rx="4" fill="#D7DBE2" />
                    <rect x="160" y="115" width="53" height="8" rx="4" fill="#D7DBE2" />
                    <rect x="160" y="128" width="45" height="8" rx="4" fill="#6881E9" />
                    <rect x="148" y="102" width="8" height="8" rx="4" fill="#D7DBE2" />
                    <rect x="148" y="115" width="8" height="8" rx="4" fill="#D7DBE2" />
                    <rect x="148" y="128" width="8" height="8" rx="4" fill="#6881E9" />
                    <rect x="40" y="94" width="90" height="50" rx="2" fill="#EEF1F4" />
                    <rect x="60" y="102" width="37" height="8" rx="4" fill="#6881E9" />
                    <rect x="60" y="115" width="58" height="8" rx="4" fill="#D7DBE2" />
                    <rect x="60" y="128" width="23" height="8" rx="4" fill="#D7DBE2" />
                    <rect x="48" y="102" width="8" height="8" rx="4" fill="#6881E9" />
                    <rect x="48" y="115" width="8" height="8" rx="4" fill="#D7DBE2" />
                    <rect x="48" y="128" width="8" height="8" rx="4" fill="#D7DBE2" />
                    <rect x="40" y="34" width="90" height="50" rx="2" fill="#EEF1F4" />
                    <rect x="60" y="42" width="32" height="8" rx="4" fill="#D7DBE2" />
                    <rect x="60" y="55" width="54" height="8" rx="4" fill="#D7DBE2" />
                    <rect x="60" y="68" width="45" height="8" rx="4" fill="#6881E9" />
                    <rect x="48" y="42" width="8" height="8" rx="4" fill="#D7DBE2" />
                    <rect x="48" y="55" width="8" height="8" rx="4" fill="#D7DBE2" />
                    <rect x="48" y="68" width="8" height="8" rx="4" fill="#6881E9" />
                    <defs>
                        <filter id="filter0_d_269_7292" x="0" y="0" width="272" height="162" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset />
                            <feGaussianBlur stdDeviation="5" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_269_7292" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_269_7292" result="shape" />
                        </filter>
                        <linearGradient id="paint0_linear_269_7292" x1="136" y1="11" x2="136" y2="151" gradientUnits="userSpaceOnUse">
                            <stop offset="0.7744" stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_269_7292" x1="136" y1="11" x2="136" y2="151" gradientUnits="userSpaceOnUse">
                            <stop offset="0.7744" stopColor="#E4E7EB" />
                            <stop offset="1" stopColor="#E4E7EB" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
            );
        case '6':
            return (<svg width="272" height="162" viewBox="0 0 272 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_269_7330)">
                    <path d="M11 16C11 13.2386 13.2386 11 16 11H256C258.761 11 261 13.2386 261 16V151H11V16Z" fill="url(#paint0_linear_269_7330)" shapeRendering="crispEdges" />
                    <path d="M261 151.5H261.5V151V16C261.5 12.9624 259.038 10.5 256 10.5H16C12.9624 10.5 10.5 12.9624 10.5 16V151V151.5H11H261Z" stroke="url(#paint1_linear_269_7330)" shapeRendering="crispEdges" />
                </g>
                <path d="M11 16C11 13.2386 13.2386 11 16 11H256C258.761 11 261 13.2386 261 16V26H11V16Z" fill="#F9FAFB" />
                <circle cx="21.5" cy="18.5" r="2.5" fill="#F46059" />
                <circle cx="29.5" cy="18.5" r="2.5" fill="#FBB751" />
                <circle cx="37.5" cy="18.5" r="2.5" fill="#4ABE52" />
                <rect x="100" y="51" width="65" height="30" rx="5" fill="#D7DBE2" />
                <rect x="100" y="96" width="65" height="30" rx="5" fill="#D7DBE2" />
                <path d="M165 66H178C179.105 66 180 66.8954 180 68V109C180 110.105 179.105 111 178 111H165" stroke="#DFE2E4" strokeWidth="2" />
                <path d="M48 49H83C84.1046 49 85 49.8954 85 51V81C85 82.1046 84.1046 83 83 83H37" stroke="url(#paint2_linear_269_7330)" strokeWidth="2" />
                <path d="M60 94H83C84.1046 94 85 94.8954 85 96V126C85 127.105 84.1046 128 83 128H50" stroke="url(#paint3_linear_269_7330)" strokeWidth="2" />
                <path d="M195 89L180 89" stroke="#DFE2E4" strokeWidth="2" />
                <path d="M100 66L85 66" stroke="#DFE2E4" strokeWidth="2" />
                <path d="M100 111L85 111" stroke="#DFE2E4" strokeWidth="2" />
                <path d="M234.99 79.6956C234.88 76.9501 233.936 74.2935 232.272 72.0463C230.609 69.7991 228.297 68.0573 225.615 67.0309C222.933 66.0045 219.996 65.7374 217.158 66.2618C214.319 66.7862 211.702 68.0797 209.62 69.9863C208.159 71.3069 206.996 72.896 206.202 74.6569C205.408 76.4179 204.999 78.3139 205 80.2298C205 80.5208 205.122 80.8 205.338 81.0058C205.555 81.2116 205.849 81.3273 206.155 81.3273H214.316C214.615 81.3275 214.902 81.2179 215.117 81.0215C215.333 80.8251 215.459 80.5571 215.471 80.2737C215.467 79.7046 215.582 79.1405 215.808 78.6138C216.035 78.0871 216.369 77.6082 216.791 77.2048C217.214 76.8014 217.716 76.4815 218.269 76.2635C218.822 76.0456 219.415 75.9339 220.014 75.9348H220.199C221.333 75.9962 222.404 76.4524 223.206 77.2163C224.009 77.9802 224.487 78.9983 224.549 80.0761C224.575 80.8492 224.382 81.6147 223.991 82.2929C223.6 82.9711 223.024 83.5372 222.324 83.932C220.036 85.2404 218.139 87.0855 216.816 89.2905C215.494 91.4954 214.789 93.9862 214.771 96.5241C214.771 96.8152 214.892 97.0943 215.109 97.3002C215.326 97.506 215.619 97.6216 215.926 97.6216H224.08C224.386 97.6216 224.68 97.506 224.896 97.3002C225.113 97.0943 225.234 96.8152 225.234 96.5241C225.242 95.6992 225.475 94.8903 225.909 94.1761C226.344 93.4619 226.965 92.8668 227.714 92.4487C230.016 91.136 231.906 89.2602 233.186 87.0171C234.466 84.7739 235.089 82.2455 234.99 79.6956Z" fill="#6881E9" />
                <path d="M220 111C222.761 111 225 108.822 225 106.135C225 103.449 222.761 101.271 220 101.271C217.239 101.271 215 103.449 215 106.135C215 108.822 217.239 111 220 111Z" fill="#6881E9" />
                <defs>
                    <filter id="filter0_d_269_7330" x="0" y="0" width="272" height="162" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset />
                        <feGaussianBlur stdDeviation="5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_269_7330" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_269_7330" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_269_7330" x1="136" y1="11" x2="136" y2="151" gradientUnits="userSpaceOnUse">
                        <stop offset="0.7744" stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_269_7330" x1="136" y1="11" x2="136" y2="151" gradientUnits="userSpaceOnUse">
                        <stop offset="0.7744" stopColor="#E4E7EB" />
                        <stop offset="1" stopColor="#E4E7EB" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_269_7330" x1="85" y1="66" x2="42" y2="54.5" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#ECEFF2" />
                        <stop offset="1" stopColor="#ECEFF2" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_269_7330" x1="86.5" y1="111" x2="48.5" y2="102" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#ECEFF2" />
                        <stop offset="1" stopColor="#ECEFF2" stopOpacity="0" />
                    </linearGradient>
                </defs>
            </svg>
            );
        case '7':
            return (<svg width="272" height="162" viewBox="0 0 272 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_225_9699)">
                    <path d="M11 16C11 13.2386 13.2386 11 16 11H256C258.761 11 261 13.2386 261 16V151H11V16Z" fill="url(#paint0_linear_225_9699)" shapeRendering="crispEdges" />
                    <path d="M261 151.5H261.5V151V16C261.5 12.9624 259.038 10.5 256 10.5H16C12.9624 10.5 10.5 12.9624 10.5 16V151V151.5H11H261Z" stroke="url(#paint1_linear_225_9699)" shapeRendering="crispEdges" />
                </g>
                <path d="M11 16C11 13.2386 13.2386 11 16 11H256C258.761 11 261 13.2386 261 16V26H11V16Z" fill="#F9FAFB" />
                <circle cx="21.5" cy="18.5" r="2.5" fill="#F46059" />
                <circle cx="29.5" cy="18.5" r="2.5" fill="#FBB751" />
                <circle cx="37.5" cy="18.5" r="2.5" fill="#4ABE52" />
                <path d="M106 71H171V76C171 78.7614 168.761 81 166 81H111C108.239 81 106 78.7614 106 76V71Z" fill="#D7DBE2" />
                <path d="M106 41C106 38.2386 108.239 36 111 36H166C168.761 36 171 38.2386 171 41V71H106V41Z" fill="#EEF1F4" />
                <path fillRule="evenodd" clipRule="evenodd" d="M133.109 49.5479C134.71 49.5479 136.008 48.3059 136.008 46.7739C136.008 45.2419 134.71 44 133.109 44C131.507 44 130.209 45.2419 130.209 46.7739C130.209 48.3059 131.507 49.5479 133.109 49.5479ZM152.836 61.3958C153.274 62.0807 152.782 62.9791 151.969 62.9791H140.465C140.46 62.9791 140.457 62.9852 140.461 62.9892C140.464 62.9932 140.461 62.9993 140.456 62.9993H125.031C124.161 62.9993 123.683 61.9861 124.238 61.3152L130.864 53.2916C131.276 52.7933 132.04 52.7933 132.451 53.2916L134.43 55.6877C134.875 56.2257 135.714 56.1747 136.09 55.5869L141.738 46.7565C142.143 46.1238 143.067 46.1238 143.472 46.7565L152.836 61.3958Z" fill="#D7DBE2" />
                <path d="M106 131H171V136C171 138.761 168.761 141 166 141H111C108.239 141 106 138.761 106 136V131Z" fill="#D7DBE2" />
                <path d="M106 101C106 98.2386 108.239 96 111 96H166C168.761 96 171 98.2386 171 101V131H106V101Z" fill="#EEF1F4" />
                <path fillRule="evenodd" clipRule="evenodd" d="M133.109 109.548C134.71 109.548 136.008 108.306 136.008 106.774C136.008 105.242 134.71 104 133.109 104C131.507 104 130.209 105.242 130.209 106.774C130.209 108.306 131.507 109.548 133.109 109.548ZM152.836 121.396C153.274 122.081 152.782 122.979 151.969 122.979H140.465C140.46 122.979 140.457 122.985 140.461 122.989C140.464 122.993 140.461 122.999 140.456 122.999H125.031C124.161 122.999 123.683 121.986 124.238 121.315L130.864 113.292C131.276 112.793 132.04 112.793 132.451 113.292L134.43 115.688C134.875 116.226 135.714 116.175 136.09 115.587L141.738 106.757C142.143 106.124 143.067 106.124 143.472 106.757L152.836 121.396Z" fill="#D7DBE2" />
                <path d="M201 105H271V110C271 112.761 268.761 115 266 115H206C203.239 115 201 112.761 201 110V105Z" fill="#6881E9" />
                <path d="M201 68C201 65.2386 203.239 63 206 63H266C268.761 63 271 65.2386 271 68V105H201V68Z" fill="#EEF1F4" />
                <path fillRule="evenodd" clipRule="evenodd" d="M229.678 79.424C231.555 79.424 233.078 77.9859 233.078 76.212C233.078 74.4381 231.555 73 229.678 73C227.8 73 226.278 74.4381 226.278 76.212C226.278 77.9859 227.8 79.424 229.678 79.424ZM252.806 93.1376C253.32 93.9302 252.751 94.9767 251.807 94.9767H238.305C238.299 94.9767 238.296 94.9836 238.299 94.9881C238.303 94.9926 238.3 94.9995 238.294 94.9995H220.194C219.183 94.9995 218.631 93.8193 219.28 93.0437L227.063 83.739C227.539 83.1697 228.414 83.1697 228.89 83.739L231.246 86.5554C231.761 87.1707 232.724 87.1119 233.16 86.4388L239.814 76.1667C240.283 75.4418 241.344 75.4418 241.814 76.1667L252.806 93.1376Z" fill="#6881E9" />
                <path d="M171 66H184C185.105 66 186 66.8954 186 68V109C186 110.105 185.105 111 184 111H171" stroke="#DFE2E4" strokeWidth="2" />
                <path d="M54 42H89C90.1046 42 91 42.8954 91 44V74C91 75.1046 90.1046 76 89 76H43" stroke="url(#paint2_linear_225_9699)" strokeWidth="2" />
                <path d="M66 102H89C90.1046 102 91 102.895 91 104V134C91 135.105 90.1046 136 89 136H56" stroke="url(#paint3_linear_225_9699)" strokeWidth="2" />
                <path d="M201 89L186 89" stroke="#DFE2E4" strokeWidth="2" />
                <path d="M106 59L91 59" stroke="#DFE2E4" strokeWidth="2" />
                <path d="M106 119L91 119" stroke="#DFE2E4" strokeWidth="2" />
                <defs>
                    <filter id="filter0_d_225_9699" x="0" y="0" width="272" height="162" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset />
                        <feGaussianBlur stdDeviation="5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_225_9699" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_225_9699" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_225_9699" x1="136" y1="11" x2="136" y2="151" gradientUnits="userSpaceOnUse">
                        <stop offset="0.7744" stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_225_9699" x1="136" y1="11" x2="136" y2="151" gradientUnits="userSpaceOnUse">
                        <stop offset="0.7744" stopColor="#E4E7EB" />
                        <stop offset="1" stopColor="#E4E7EB" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_225_9699" x1="91" y1="59" x2="48" y2="47.5" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#ECEFF2" />
                        <stop offset="1" stopColor="#ECEFF2" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_225_9699" x1="92.5" y1="119" x2="54.5" y2="110" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#ECEFF2" />
                        <stop offset="1" stopColor="#ECEFF2" stopOpacity="0" />
                    </linearGradient>
                </defs>
            </svg>
            );
        case '8':
            return (<svg width="272" height="162" viewBox="0 0 272 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_225_9608)">
                    <path d="M11 16C11 13.2386 13.2386 11 16 11H256C258.761 11 261 13.2386 261 16V151H11V16Z" fill="url(#paint0_linear_225_9608)" shapeRendering="crispEdges" />
                    <path d="M261 151.5H261.5V151V16C261.5 12.9624 259.038 10.5 256 10.5H16C12.9624 10.5 10.5 12.9624 10.5 16V151V151.5H11H261Z" stroke="url(#paint1_linear_225_9608)" shapeRendering="crispEdges" />
                </g>
                <path d="M11 16C11 13.2386 13.2386 11 16 11H256C258.761 11 261 13.2386 261 16V26H11V16Z" fill="#F9FAFB" />
                <circle cx="21.5" cy="18.5" r="2.5" fill="#F46059" />
                <circle cx="29.5" cy="18.5" r="2.5" fill="#FBB751" />
                <circle cx="37.5" cy="18.5" r="2.5" fill="#4ABE52" />
                <path d="M108.5 87.4998L103.5 87.4998C101.57 87.4998 99.9999 89.0698 99.9999 90.9998L99.9999 114C99.9999 115.93 101.57 117.5 103.5 117.5L108.5 117.5C110.43 117.5 112 115.93 112 114L112 90.9998C112 89.0698 110.43 87.4998 108.5 87.4998Z" fill="#D7DBE2" />
                <path d="M86.4379 132C88.438 132 89.438 131 89.438 126C89.438 121.248 94.04 117.424 97 115.454L97 90.6778C93.798 89.1958 87.3879 86.9997 77.4379 86.9997L74.2379 86.9997C70.3379 86.9997 67.0178 89.7998 66.3578 93.6398L64.1178 106.64C63.2778 111.54 67.0378 116 71.9979 116L81.4379 116C81.4379 116 79.9379 119 79.9379 124C79.9379 130 84.4379 132 86.4379 132Z" fill="#D7DBE2" />
                <path d="M162.5 80.5002H167.5C169.43 80.5002 171 78.9302 171 77.0002V54C171 52.07 169.43 50.5 167.5 50.5H162.5C160.57 50.5 159 52.07 159 54V77.0002C159 78.9302 160.57 80.5002 162.5 80.5002Z" fill="#D7DBE2" />
                <path d="M184.562 36C182.562 36 181.562 37 181.562 42C181.562 46.7521 176.96 50.5761 174 52.5461V77.3222C177.202 78.8042 183.612 81.0003 193.562 81.0003H196.762C200.662 81.0003 203.982 78.2002 204.642 74.3602L206.882 61.3601C207.722 56.4601 203.962 52.0001 199.002 52.0001H189.562C189.562 52.0001 191.062 49.0001 191.062 44C191.062 38 186.562 36 184.562 36Z" fill="#D7DBE2" />
                <path fillRule="evenodd" clipRule="evenodd" d="M173.823 82.6785C172.267 84.4105 170.01 85.5005 167.5 85.5005H162.5C157.809 85.5005 154 81.6919 154 77.0005V64H98C95.2386 64 93 66.2386 93 69V83.9432C94.5986 84.3825 95.9894 84.8568 97.1766 85.3215C98.7331 83.5895 100.99 82.4995 103.5 82.4995H108.5C113.191 82.4995 117 86.3081 117 90.9995L117 104H173C175.761 104 178 101.761 178 99V84.0569C176.401 83.6175 175.011 83.1432 173.823 82.6785Z" fill="#6881E9" />
                <defs>
                    <filter id="filter0_d_225_9608" x="0" y="0" width="272" height="162" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset />
                        <feGaussianBlur stdDeviation="5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_225_9608" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_225_9608" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_225_9608" x1="136" y1="11" x2="136" y2="151" gradientUnits="userSpaceOnUse">
                        <stop offset="0.7744" stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_225_9608" x1="136" y1="11" x2="136" y2="151" gradientUnits="userSpaceOnUse">
                        <stop offset="0.7744" stopColor="#E4E7EB" />
                        <stop offset="1" stopColor="#E4E7EB" stopOpacity="0" />
                    </linearGradient>
                </defs>
            </svg>
            );

        default:
            return <></>;
    }
};
