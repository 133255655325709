import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	h2 {
		text-align: center;
		font-weight: 600;
		font-size: ${CSSVars.fontSize2XL};
		color: ${CSSVars.gray900};
		margin-bottom: 50px;

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			font-size: ${CSSVars.fontSize4XL};
		}
	}

	.bracket-types-wrapper {
		gap: 30px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		max-width: 1420px;
		margin: 0 auto;
		justify-content: center;

		.card {
			background-color: ${CSSVars.white};
			border: 1px solid ${CSSVars.gray200};
			border-radius: 5px;
			cursor: pointer;
			position: relative;
			display: flex;
			flex-direction: column;
			flex: 1;
			transition: 0.2s;
			min-width: 280px;
			max-width: 330px;

			@media (max-width: 655px) {
				max-width: 100%;
			}

			&:hover {
				border: 1px solid ${CSSVars.gray300};
			}

			.cover-img {
				width: 100%;
				height: 170px;
				object-fit: cover;
				border-radius: 5px 5px 0 0;
				background-color: #f8f8fa;
				position: relative;

				>* {
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					bottom: -11px;
				}
			}

			.details {
				display: flex;
				flex-direction: column;
				gap: 5px;
				color: #1e2a3b;
				padding: 20px;

				h3 {
					font-weight: 500;
					font-size: 18px;
					line-height: 20px;
					margin: 0;

					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
		}

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			gap: 30px;
		}
	}
`;
