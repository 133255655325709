import Image from 'next/image';
import React from 'react';
import { CSSProperties } from 'styled-components';
import { Section } from '../section/section.comp';
import { StyledDiv } from './bracketTypes.styles';
import Link from 'next/link';
import { BracketIcon } from './bracketIcon.comp';

export const BracketTypes = ({ style = {} }: { style?: CSSProperties; }) => {
    return (
        <Section style={style}>
            <StyledDiv>
                <h2>Bracket Types</h2>
                <div className="bracket-types-wrapper">
                    <Link
                        title='single-elimination-bracket'
                        className="card"
                        href={'/types/single-elimination-bracket'}
                    >
                        <div className="cover-img">
                            <BracketIcon type='1' />
                        </div>
                        <div className="details">
                            <h3>
                                Single Elimination
                            </h3>
                        </div>
                    </Link>
                    <Link
                        title='double-elimination-bracket'
                        className="card"
                        href={'/types/double-elimination-bracket'}
                    >
                        <div className="cover-img">
                            <BracketIcon type='2' />
                        </div>
                        <div className="details">
                            <h3>
                                Double Elimination
                            </h3>
                        </div>
                    </Link>
                    <Link
                        title='march-madness-bracket'
                        className="card"
                        href={'/types/march-madness-bracket'}
                    >
                        <div className="cover-img">
                            <BracketIcon type='3' />
                        </div>
                        <div className="details">
                            <h3>
                                March Madness
                            </h3>
                        </div>
                    </Link>
                    <Link
                        title='round-robin-bracket'
                        className="card"
                        href={'/types/round-robin-bracket'}
                    >
                        <div className="cover-img">
                            <BracketIcon type='4' />
                        </div>
                        <div className="details">
                            <h3>
                                Round Robin
                            </h3>
                        </div>
                    </Link>
                    <Link
                        title='group-stage-bracket'
                        className="card"
                        href={'/types/group-stage-bracket'}
                    >
                        <div className="cover-img">
                            <BracketIcon type='5' />
                        </div>
                        <div className="details">
                            <h3>
                                Group Stage
                            </h3>
                        </div>
                    </Link>
                    <Link
                        title='prediction-bracket'
                        className="card"
                        href={'/types/prediction-bracket'}
                    >
                        <div className="cover-img">
                            <BracketIcon type='6' />
                        </div>
                        <div className="details">
                            <h3>
                                Brackets with Predictions
                            </h3>
                        </div>
                    </Link>
                    <Link
                        title='images-bracket'
                        className="card"
                        href={'/types/images-bracket'}
                    >
                        <div className="cover-img">
                            <BracketIcon type='7' />
                        </div>
                        <div className="details">
                            <h3>
                                Brackets with Images
                            </h3>
                        </div>
                    </Link>
                    <Link
                        title='voting-bracket'
                        className="card"
                        href={'/types/voting-bracket'}
                    >
                        <div className="cover-img">
                            <BracketIcon type='8' />
                        </div>
                        <div className="details">
                            <h3>
                                Voting Brackets
                            </h3>
                        </div>
                    </Link>
                </div>
            </StyledDiv>
        </Section>
    );
};
