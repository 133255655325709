import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledHero = styled.section`
	overflow: hidden;
	background-color: ${CSSVars.gray700};
	position: relative;
	height: fit-content;

	.hero-content-container {
		padding: 50px 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 1500px;
		margin: 0 auto;

		@media screen and (min-width: ${CSSVars.breakpointSM}) {
			padding: 100px 40px 150px;
		}

		@media screen and (min-width: ${CSSVars.breakpointXL}) {
			padding: 200px 40px 300px;
		}

		@media screen and (min-width: ${CSSVars.breakpoint2XL}) {
			padding: 250px 40px 400px;
		}

		.details {
			z-index: 2;
			color: white;
			/* margin-left: 250px; */
			max-width: 650px;
			display: flex;
			flex-direction: column;
			gap: 10px;

			h1 {
				font-size: 50px;
				font-weight: 600;
				font-size: ${CSSVars.fontSize2XL};
				background-color: ${CSSVars.gray700};
				border-radius: 10px;
				padding: 0 10px;
				width: fit-content;

				@media screen and (max-width: 730px) {
					background-color: transparent;
					padding: 0;
				}

				@media screen and (min-width: ${CSSVars.breakpointSM}) {
					font-size: ${CSSVars.fontSize3XL};
				}

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					font-size: ${CSSVars.fontSize4XL};
					max-width: 850px;
				}

				@media screen and (min-width: ${CSSVars.breakpointXL}) {
					font-size: ${CSSVars.fontSize5XL};
					max-width: 1150px;
				}
			}

			h2 {
				font-weight: 300;
				font-size: ${CSSVars.fontSizeLG};

				background-color: ${CSSVars.gray700};
				border-radius: 10px;
				padding: 0 10px;

				@media screen and (max-width: 730px) {
					background-color: transparent;
					padding: 0;
				}

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					max-width: 850px;
					font-size: ${CSSVars.fontSizeXL};
				}

				@media screen and (min-width: ${CSSVars.breakpointXL}) {
					max-width: 820px;
				}
			}

			a {
				margin: 20px 0 0 10px;
				padding: 12px 24px;
				font-size: 18px;
				font-weight: 600;

				@media screen and (max-width: 730px) {
					margin: 20px 0 0;
				}
			}
		}
	}

	.bracket-images {
		position: absolute;
		right: -380px;
		top: -80px;
	}
`;
