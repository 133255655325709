import { BracketTypes } from "@/components/bracketTypes/bracketTypes.comp";
import { Hero } from "../components/hero/hero.comp";
import { Layout } from "../components/layout/layout.comp";
import { TrustedBy } from "../components/trustedBy/trustedBy.comp";
import { getDataFromDB } from "../lib/mongodb";
import { IPlugin } from "../types";
import { WidgetFeatures } from "@/components/widgetFeatures/widgetFeatures.comp";
import { CSSVars } from "@/styles/styles.config";
import { SupportedPlatforms } from "@/components/supportedPlatforms/supportedPlatforms.comp";
import Script from "next/script";

export default function Home({
  apps,
  brackets,
}: {
  apps: IPlugin[];
  brackets: IPlugin;
}) {
  return (
    <Layout
      showFooter={true}
      showHeader={true}
      apps={apps}
      isDark
      image="https://website-assets.commoninja.com/distribution/1692691663291_og_brackets.png"
    >
      <Hero />
      <BracketTypes />
      <TrustedBy style={{ backgroundColor: `${CSSVars.gray100}` }} />
      <WidgetFeatures widget={brackets} />
      <SupportedPlatforms />
      <Script id="product-scheme" type="application/ld+json">{`
          {
              "@context": "https://schema.org/", 
              "@type": "Product", 
              "name": "Online Bracket Maker",
              "image": "https://website-assets.commoninja.com/distribution/1692691663291_og_brackets.png",
              "description": "The ultimate bracket maker platform",
              "aggregateRating": {
                "@type": "AggregateRating",
                "worstRating": "1",
                "bestRating": "5",
                "ratingCount": "326",
                "ratingValue": "4.9"
              }
          }
            `}</Script>
    </Layout>
  );
}

export async function getStaticProps() {
  const apps = await getDataFromDB<IPlugin>("apps", {
    status: "published",
  });
  const [brackets] = await getDataFromDB<IPlugin>("apps", {
    status: "published",
    slug: "brackets",
  });

  return {
    props: { apps, brackets },
  };
}
